<template>
  <div class="mt-3">
    <div v-for="(group, f) in meal.additions" :key="f">
      <h3 class="black--text">
        {{ group.description[$store.state.default_lang] }} <span v-if="has_order">
          ({{ group.maximum }}/ {{ group.adds.length }} )
        </span>
      </h3>
      <v-list dense>
        <v-radio-group dense class="d-block" v-model="add_ind">
          <div v-for="(add, i) in group.adds" :key="i">
            <v-list-item class="px-0" v-if="add.status != 0">
              <v-list-item-content
                @click="select_add(f, i, add)"
                class="font-weight-bold"
              >
                <div class="d-flex pr-3 align-center">
                  <div v-if="meal.status != 2 && add.status != 2">
                    <v-checkbox
                      class="checkbox"
                      :value="add.checked"
                      v-if="(group.maximum > 1 || !group.is_max) && (has_order)"
                    ></v-checkbox>
                    <v-radio
                      :value="i"
                      v-if="(group.maximum == 1 && group.is_max) && (has_order)"
                    ></v-radio>
                  </div>
                  <span
                    :class="{
                      'grey--text text-decoration-line-through':
                        add.status == 2,
                    }"
                  >
                    {{
                      $store.state.default_lang
                        ? add.name[[$store.state.default_lang]]
                        : ""
                    }}
                  </span>

                  <span
                    class="price d-inline-block"
                    :class="{
                      'grey--text text-decoration-line-through':
                        add.status == 2,
                    }"
                  >
                    {{ group.is_free ? 0 : add.price }} ₪
                  </span>
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip
                  color="red lighten-3"
                  x-small
                  class="px-2 white--text"
                  v-if="add.status == 2"
                  >غير متوفر</v-chip
                >
                <div
                  v-if="group.quantity && meal.status != 2 && add.status != 2 && add.checked"
                >
                
                  <v-btn-toggle width="30%" class="elevation-0">
                    <v-btn
                      height="33"
                      min-width="25"
                      @click.native="dec_quan(add.checked, add.id, f, i)"
                      class="btn-remove border-none lighten-4 primary primary--text px-2"
                    >
                      <v-icon color="primary">remove</v-icon>
                    </v-btn>

                    <v-btn
                      min-width="30"
                      height="33"
                      class="btn-quan px-1 border-none subtitle-1 font-weight-bold lighten-4 primary primary--text"
                    >
                      {{ add.quantity }}
                    </v-btn>
                    <v-btn
                      class="px-2 btn-add lighten-4 primary primary--text border-none"
                      height="33"
                      min-width="25"
                      @click.native="inc_qun(add.checked, add.id, f, i)"
                    >
                      <v-icon color="primary">add</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-radio-group>
      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  props: ["meal", "additions", "cart_item", 'has_order'],
  name: "Additions",
  data() {
    return {
      add_ind: null,
      num: 0,
    };
  },
  methods: {
    inc_qun(checked, id, g_ind, add_ind) {
      this.meal.additions[g_ind].adds[add_ind].quantity++;
      if (checked) {
        let item_index = this.cart_item.additions.findIndex((e) => e.id == id);
        this.cart_item.additions[item_index].quantity++;
      }
    },
    dec_quan(checked, id, g_ind, add_ind) {
      let quan = this.meal.additions[g_ind].adds[add_ind].quantity;
      if (quan != 1) {
        this.meal.additions[g_ind].adds[add_ind].quantity--;
        if (checked) {
          let item_index = this.cart_item.additions.findIndex(
            (e) => e.id == id
          );
          this.cart_item.additions[item_index].quantity--;
        }
      }
    },
    select_add(group_ind, add_ind, add) {
      if(this.has_order) {
        if (add.checked) {
        add.checked = !add.checked;
        this.remove_cart(add, "multiple");
      } else {
        let group = this.meal.additions[group_ind];
        let adds_selected = this.meal.additions[group_ind].adds.filter(
          (e) => e.checked
        );
        if (group.is_max && this.meal.additions[group_ind].maximum != 1) {
          if (adds_selected.length + 1 > group.maximum) {
            let min = Math.min(...adds_selected.map((item) => item.select_num));
            let first_check = this.meal.additions[group_ind].adds.findIndex(
              (e) => e.select_num == min
            );
            group.adds[first_check].checked = false;
            this.remove_cart(group.adds[first_check], "multiple");
          }
        } else if (this.meal.additions[group_ind].maximum == 1) {
          this.add_ind = add_ind;
          this.remove_cart(add, "single");
        }
        add.checked = !add.checked;
        add.select_num = this.num++;
        this.add_cart(group, add);
      }
      }
    },
    add_cart(group, add) {
      if (group) {
        this.$emit("add_cart", {
          type: "additions",
          item: {
            is_free: group.is_free,
            price: group.is_free ? 0 : add.price,
            id: add.id,
            name: add.name,
            quantity: add.quantity,
          },
        });
        this.meal.additions.forEach((e) => {
          e.quantity = 1;
        });
      }
    },
    remove_cart(ev, type) {
      this.$emit("remove_cart", {
        type: type,
        kind: "additions",
        id: ev.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .checkbox {
    position: relative;
    top: 6px;
  }
  .v-input--radio-group__input {
    display: block !important;
  }
  .v-input--selection-controls {
    margin-top: 0 !important;
  }
  .v-input__slot {
    margin-bottom: 0 !important;
  }
}
</style>
