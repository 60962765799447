var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"categories d-flex px-0",class:{ body: _vm.is_scroll },style:({
      'margin-top': !_vm.$store.getters.permissions.includes('video')
        ? '50px'
        : '10px',
    }),attrs:{"id":"cats_btn"}},[_c('v-container',{staticClass:"d-flex pb-5 pt-0"},_vm._l((_vm.categories),function(cat,i){return _c('v-chip',{key:i,staticClass:"chip",attrs:{"outlined":cat.id != _vm.cat_id,"color":cat.id == _vm.cat_id ? 'primary' : 'secondary'},on:{"click":function($event){_vm.switch_cat(cat.id), _vm.get_win($event)}}},[_vm._v(" "+_vm._s(cat.category_name[_vm.$store.state.default_lang])+" ")])}),1)],1),_c('v-row',{staticStyle:{"margin-bottom":"100px"}},_vm._l((_vm.products),function(meal,x){return _c('v-col',{key:x,attrs:{"cols":"6","sm":"3"}},[(meal.status == 1 || meal.status == 2)?_c('v-card',{staticClass:"product_card",attrs:{"color":_vm.info.cardColor},on:{"click":function($event){return _vm.item_preview(meal, _vm.cat_id, _vm.cat_sale)}}},[(_vm.cat_sale != 0 || !meal.sale || meal.more_price_sale != 0)?_c('div',{staticClass:"sale primary body--text"},[_vm._v(" تخفيض "),(
              _vm.cat_sale != 0 && meal.sale == 0 && meal.more_price_sale == 0
            )?_c('span',[_c('br'),_vm._v(" % "+_vm._s(_vm.cat_sale)+" ")]):(meal.more_price_sale == 0 && !meal.sale)?_c('span',[_c('br'),_vm._v(" % "+_vm._s(Math.floor(((meal.price - meal.sale) / meal.price) * 100))+" ")]):(meal.more_price_sale != 0)?_c('span',[_c('br'),_vm._v(" % "+_vm._s(Math.floor( ((meal.more_price - meal.more_price_sale) / meal.more_price) * 100 ))+" ")]):_vm._e()]):_vm._e(),(meal.images.length != 0)?_c('div',{staticClass:"img",style:(("background-image:url('" + (_vm.$store.state.def_path) + "/" + (meal.images[0].image)))}):_vm._e(),(meal.images.length == 0)?_c('div',{staticClass:"img",style:(("background-image:url('" + (_vm.$store.state.def_path) + "/uploads/none_img.png"))}):_vm._e(),_c('div',{staticClass:"px-4 black--text"},[_c('h4',{staticClass:"mt-2 name",style:({ color: ("" + (_vm.info.secondaryColor)) })},[_vm._v(" "+_vm._s(meal.name[_vm.$store.state.default_lang])+" ")]),_c('p',{staticClass:"mb-2 desc",style:({ color: ("" + (_vm.info.secondaryColor)) })},[_vm._v(" "+_vm._s(meal.description[_vm.$store.state.default_lang] ? meal.description[_vm.$store.state.default_lang] : "...")+" ")])]),_c('div',{staticClass:"line",style:({
            'border-color': ("" + (_vm.info.secondaryColor)),
            opacity: '0.5',
          })}),_c('p',{staticClass:"product_card__price px-4",style:({ color: ("" + (_vm.info.secondaryColor)) })},[_c('span',{staticClass:"me-2"},[_vm._v(" "+_vm._s(_vm.pro_price[x])+" ₪ ")]),(
              _vm.cat_sale != 0 && meal.sale == 0 && meal.more_price_sale == 0
            )?_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(" "+_vm._s(meal.prices.length == 0 ? meal.price : meal.prices[0].price)+" ₪ ")]):(meal.more_price_sale == 0 && !meal.sale)?_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(" "+_vm._s(meal.price)+" ₪ ")]):(meal.more_price_sale != 0)?_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(" "+_vm._s(meal.more_price)+" ₪ ")]):_vm._e(),(meal.status == 2)?_c('v-chip',{staticClass:"ms-auto px-2",attrs:{"color":"red lighten-3 white--text","x-small":""}},[_vm._v(" غير متوفر")]):_vm._e()],1)]):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }