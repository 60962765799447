<template>
  <div class="mt-3">
    <h3 v-if="meal.free_additions.length != 0" class="black--text">
      {{ $store.getters.trans("Product", "product_free_addition") }}
    </h3>
    <v-list dense v-if="meal.free_additions.length != 0">
      <div v-for="(free_add, i) in meal.free_additions" :key="i">
        <v-list-item
          v-if="free_add.status != 0"
          class="px-0"
          @click.native="select_add(free_add)"
        >
          <v-list-item-content class="font-weight-bold" :class="{'grey--text text-decoration-line-through' : free_add.status == 2}">
            <div class="d-flex pr-3 align-center">
              <v-checkbox
                v-model="free_add.used"
                v-if="meal.status != 2 && free_add.status != 2 && has_order"
                class="mr-2 checkbox"
              ></v-checkbox>
              {{
                $store.state.default_lang
                  ? free_add.name[[$store.state.default_lang]]
                  : ""
              }}
              <span class="price d-inline-block">0 ₪ </span>
            </div>
          </v-list-item-content>
          <v-list-item-action v-if="free_add.status == 2">
            <v-chip
              color="red lighten-3"
              x-small
              class="px-2 white--text"
              v-if="free_add.status == 2"
              >غير متوفر</v-chip
            >
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-list>
    <!--/ free addtions  -->
  </div>
</template>

<script>
export default {
  props: ["meal", "has_order"],
  name: "FreeAdditions",
  data() {
    return {};
  },
  methods: {
    select_add(free_add) {
      if (free_add.status != 2) {
        free_add.used = !free_add.used;
        this.$emit("add_used", { item: free_add, is_used: free_add.used });
      }
    },
    add_cart(free_add) {
      this.$emit("add_cart", {
        type: "free_additions",
        item: {
          is_free: true,
          id: free_add.id,
          name: free_add.name,
        },
      });
    },
    remove_cart(ev) {
      this.$emit("remove_cart", {
        type: "multiple",
        kind: "free_additions",
        id: ev.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .checkbox {
    position: relative;
    top: 6px;
  }
  .v-input--radio-group__input {
    display: block !important;
  }
  .v-input--selection-controls {
    margin-top: 0 !important;
  }
  .v-input__slot {
    margin-bottom: 0 !important;
  }
}
</style>
