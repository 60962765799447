<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="500"
    scrollable
    :value="cart_dialog"
    @input="dialog_prop($event)"
  >
    <v-card class="meal rounded-xl">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          class="close"
          @click="$emit('close_dialog')"
          fab
          color="white"
          small
        >
          <v-icon color="black">close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <h2 class="black--text mb-4">
          {{ $store.getters.trans("cart", "cart_title") }}
        </h2>
        <v-list class="mt-3" dense>
          <v-list-item
            v-for="(item, i) in cart"
            :key="i"
            class="px-0 align-center"
          >

            <v-list-item-icon class="mt-0 align-self-start">
              <v-chip
                label
                style="width: 35px; height: 35px; border-width: 2px"
                outlined
                class="text-center pa-0 d-block primary--text font-weight-bold"
                >{{ item.quantity }}</v-chip
              >
            </v-list-item-icon>
            <v-list-item-content class="font-weight-bold pt-0 align-self-start">
              <div
                class="d-flex flex-wrap justify-sm-space-between align-center"
              >
                <div>
                  <h3 class="mb-2">
                    {{ item.product_name[$store.state.default_lang] }}
                  </h3>
                  <ul style="list-style: none pa-0">
                    <li
                      :class="[
                        { 'grey--text': free_add.used },
                        { 'red--text': !free_add.used },
                        { 'text-decoration-line-through': !free_add.used },
                      ]"
                      class="mb-2 lighten-1"
                      v-for="(free_add, s) in item.free_additions"
                      :key="s"
                    >
                      {{ free_add.name[$store.state.default_lang] }}(0 ₪)
                      {{ free_add.used ? "" : "بدون" }}
                    </li>
                  </ul>
                  <ul style="list-style: none pa-0">
                    <li
                      class="mb-2 grey--text lighten-1"
                      v-for="(add, x) in item.additions"
                      :key="x"
                    >
                      {{ add.name[$store.state.default_lang] }}({{ add.price }}
                      ₪)
                    </li>
                  </ul>
                  <p class="primary--text mb-3">{{ total_item[i] }} ₪</p>
                </div>

                <!--/ quantity -->
              </div>
            </v-list-item-content>
            <v-list-item-action class="align-self-start mt-0">
              <div class="d-flex align-center">
                <img
                  v-if="item.item_img"
                  width="55"
                  height="40"
                  :src="`${$store.state.def_path}/${item.item_img}`"
                  alt=""
                />
                <img
                  v-else
                  :src="`${$store.state.def_path}/none_img.png`"
                  width="55"
                />
                <v-btn icon color="red" @click="delete_item(item.id, i)">
                  <v-icon>close</v-icon>
                </v-btn>
              </div>

              <div class=" mt-3">
                <v-btn-toggle width="30%" class="elevation-0">
                  <v-btn
                    height="37"
                    min-width="25"
                    @click="dec_quan(i, item.id)"
                    class="btn-remove border-none lighten-4 primary primary--text px-2"
                  >
                    <v-icon color="primary">remove</v-icon>
                  </v-btn>

                  <v-btn
                    min-width="30"
                    height="37"
                    class="btn-quan px-1 border-none subtitle-1 font-weight-bold lighten-4 primary primary--text"
                  >
                    {{ item.quantity }}
                  </v-btn>
                  <v-btn
                    class="px-2 btn-add lighten-4 primary primary--text border-none"
                    height="37"
                    min-width="25"
                    @click="inc_qun(i, item.id)"
                  >
                    <v-icon color="primary">add</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-textarea v-model="notes" outlined rows="4">
          <template slot="label">
            {{ $store.getters.trans("cart", "cart_notes") }}
          </template>
        </v-textarea>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-btn
          height="50"
          width="100%"
          :style="{ 'background-color': `${info.primaryColor}` }"
          class="pa-3 elevation-3 body--text rounded font-weight-bold d-flex justify-space-between"
          @click="check_code()"
        >
          <div>
            <span class="badge white primary--text">{{ cart.length }}</span>
            {{ $store.getters.trans("cart", "cart_send_order") }}
          </div>
          <div>₪ {{ total_cart }}</div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Cart",
  props: ["cart_dialog", "info", "order_side"],
  data() {
    return {
      notes: "",
    };
  },
  computed: {
    cart() {
      return this.$store.getters.cart;
    },
    total_item() {
      let totals = [];
      this.cart.forEach((e) => {
        let price = e.product_price;
        e.additions.forEach((s) => {
          price += (s.quantity * s.price);
        });
        totals.push(price * e.quantity);
      });
      return totals;
    },
    total_cart() {
      let total = 0;
      this.total_item.forEach((e) => {
        total += e;
      });
      return total;
    },
  },

  methods: {
    inc_qun(index, id) {
      let quan = ++this.$store.state.cart[index].quantity;
      this.update_quantity(quan, id);
    },
    dec_quan(index, id) {
      if (this.$store.state.cart[index].quantity != 1) {
        let quan = --this.$store.state.cart[index].quantity;
        this.update_quantity(quan, id);
      }
    },
    update_quantity(quan, id) {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `cart/${id}`,
            method: "put",
          },
          data: {
            quantity: quan,
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    dialog_prop(ev) {
      if (!ev) {
        this.$emit("close_dialog");
        this.notes = "";
      }
    },
    check_code() {
      if (this.$store.getters.permissions.includes("tables")) {
        let code = localStorage.getItem("table_code");
        if (code) {
          this.$store
            .dispatch("public__request", {
              config: {
                url: "check_code",
                method: "post",
              },
              data: {
                table_code: code,
                store_id: this.$store.state.store_info.id,
              },
            })
            .then((res) => {
              if (res.data.message == "not_found") {
                localStorage.removeItem("table_code");
                this.$emit("fade_check_code");
              } else {
                this.send_order();
              }
            });
        } else {
          this.$emit("fade_check_code");
        }
      } else {
        this.send_order();
      }
    },
    send_order() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "store_order",
            method: "post",
          },
          data: {
            cart: this.$store.state.cart,
            total: this.total_cart,
            store_id: this.$store.state.store_info.id,
            notes: this.notes,
            order_side: this.$store.getters.permissions.includes("tables")
              ? this.order_side
              : "online",
            table_name: localStorage.getItem("table_name"),
            table_code: localStorage.getItem("table_code"),
          },
        })
        .then((res) => {
          console.log(res);
          this.$store.state.cart = Object.assign([]);
          this.$store.state.cart_alert = true;
          this.$emit("close_dialog");
          this.$emit("order_side_fun", null);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    delete_item(id, index) {
      this.$store.state.cart.splice(index, 1);
      this.$store
        .dispatch("public__request", {
          config: {
            url: `cart/${id}`,
            method: "delete",
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .border-none {
    border: 0 !important;
  }
  .badge {
    width: 18px;
    height: 18px;
    line-height: 19px;
    display: inline-block;
    border-radius: 50%;
    font-size: 11px;
    position: relative;
    top: -2px;
    margin-left: 2px;
  }
  .v-dialog {
    border-radius: 21px;
    @media (max-width: 767px) {
      margin: 0;
      margin-top: auto;
      border-radius: 0;
      .v-card {
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }
}
</style>
