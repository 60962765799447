import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

import home from "@/views/home.vue";
import category from "@/views/category.vue";
import notfound from "@/views/not_found.vue";
// import about from "@/views/about.vue";
// import contacts from "@/views/contacts.vue";
// import time_of_work from "@/views/time.vue";

const routes = [
  {
    path: "/:menu?",
    name: "home",
    component: home,
    meta: {
      title: "home",
    },
  },
  {
    path: "/c/:menu/:id",
    name: "category",
    component: category,
    meta: {
      title: "category",
    },
  },
  {
    path: "/*",
    name: "notfound",
    component: notfound,
    meta: {
      title: "خطأ فى عنوان المينيو",
    },
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   component: about,
  //   meta: {
  //     title: "about",
  //   },
  // },
  // {
  //   path: "/time",
  //   name: "time",
  //   component: time_of_work,
  //   meta: {
  //     title: "time",
  //   },
  // },
  // {
  //   path: "/contacts",
  //   name: "contacts",
  //   component: contacts,
  //   meta: {
  //     title: "contacts",
  //   },
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

router.beforeEach(async (to, from, next) => {
  next();

});
