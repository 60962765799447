<template>
  <v-dialog persistent :value="order_side_dialog" max-width="400">
    <v-card>
      <v-card-text class="pt-10">
        <v-stepper elevation="0" alt-labels v-model="step">
          <v-stepper-content class="pa-0" step="1">
            <div class="d-flex justify-space-around">
              <v-btn
                @click="step++"
                height="120"
                class="d-block"
                color="primary"
                outlined
                style="border-width:3px"
              >
                <div class="text-center py-3">
                  <h2 class="mb-2">
                    {{
                      $store.getters.trans(
                        "customer_place",
                        "customer_place_inside"
                      )
                    }}
                  </h2>
                  <v-icon color="primary" x-large> restaurant </v-icon>
                </div>
              </v-btn>
              <v-btn
                @click="$emit('order_side', 'online'), $emit('code_found')"
                outlined
                height="120"
                class="d-block"
                color="primary"
                  style="border-width:3px"
              >
                <div class="text-center py-3">
                  <h2 class="mb-2">
                    {{
                      $store.getters.trans(
                        "customer_place",
                        "customer_place_outside"
                      )
                    }}
                  </h2>
                          <v-icon color="primary" x-large> shopping_bag </v-icon>
                </div>
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content class="pa-0" step="2">
            <v-alert v-if="code_err" type="error" dens class="text-center">
              {{
                $store.getters.trans(
                  "customer_place",
                  "customer_place_table_code_err"
                )
              }}
            </v-alert>
            <v-form ref="form">
              <v-text-field
                v-model="table_code"
                :rules="[
                  (v) =>
                    !!v ||
                    `${$store.getters.trans(
                      'customer_place',
                      'customer_place_table_name'
                    )}`,
                ]"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{
                    $store.getters.trans(
                      "customer_place",
                      "customer_place_table_name"
                    )
                  }}
                </template>
              </v-text-field>
              <v-btn
                @click="check_code()"
                width="100%"
                class="font-weight-bold mt-3"
                height="60"
                color="primary"
              >
                {{
                  $store.getters.trans(
                    "customer_place",
                    "customer_place_confirm"
                  )
                }}
              </v-btn>
            </v-form>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="step == 2" class="fw-bold" @click="back()" color="primary">
          {{ $store.getters.trans("customer_place", "customer_place_back") }}
        </v-btn>
        <v-btn
          v-if="step == 1"
          class="fw-bold"
          @click="$emit('close_dialog')"
          color="error"
        >
          {{ $store.getters.trans("customer_place", "customer_place_close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "OrderSide",
  props: ["order_side_dialog"],
  data() {
    return {
      step: 1,
      table_code: null,
      code_err: false,
    };
  },
  methods: {
    close_dialog() {
      this.$emit("close_dialog");
    },
    back() {
      this.step = 1;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    check_code() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("public__request", {
            config: {
              url: "check_code",
              method: "post",
            },
            data: {
              table_code: this.table_code,
              store_id: this.$store.state.store_info.id,
            },
          })
          .then((res) => {
            if (res.data.message == "not_found") {
              this.code_err = true;
            } else {
              this.code_err = false;
              localStorage.setItem("table_code", this.table_code);
              localStorage.setItem("table_name", res.data.data.table_name);
              this.$emit("code_found");
              this.step = 1;
              this.table_code = null;
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
  },
};
</script>
