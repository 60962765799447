<template>
  <div style="margin-bottom: 50px">
    <section class="cat" v-for="(item, i) in items" :key="i">
      <div v-if="item.products.length != 0">
        <h3
          v-if="item.products.length != 0"
          class="pt-4 pb-0 d-flex justify-space-between align-center"
        >
          <span
            :style="{ color: `${info.primaryColor}` }"
            class="section-title pe-md-0"
            >{{ item.category_name[$store.state.default_lang] }}</span
          >
          <router-link
            :style="{ 'background-color': `${info.primaryColor}` }"
            class="view-all"
            :to="{
              name: 'category',
              params: {
                id: item.id,
                menu: $route.query.menu
                  ? $route.query.menu
                  : $route.params.menu,
              },
            }"
          >
            <span class="body--text">{{
              $store.getters.trans("home", "home_view_all")
            }}</span>
          </router-link>
        </h3>

        <div class="card_wrapper">
          <div v-for="(meal, x) in item.products" :key="x">
            <v-card
              v-if="meal.status == 1 || meal.status == 2"
              class="product_card"
              @click="item_preview(meal, item.id, item.sale)"
              :color="info.cardColor"
            >
              <div
                v-if="item.sale || meal.sale || meal.more_price_sale"
                class="sale primary body--text"
              >
                تخفيض
                <span
                  v-if="
                    item.sale != 0 &&
                    meal.sale == 0 &&
                    meal.more_price_sale == 0
                  "
                >
                  <br />
                  % {{ item.sale }}
                </span>
                <span v-else-if="meal.more_price_sale == 0 && !meal.sale">
                  <br />
                  %
                  {{
                    Math.floor(((meal.price - meal.sale) / meal.price) * 100)
                  }}
                </span>
                <span v-else-if="meal.more_price_sale != 0">
                  <br />
                  %
                  {{
                    Math.floor(
                      ((meal.more_price - meal.more_price_sale) /
                        meal.more_price) *
                        100
                    )
                  }}
                </span>
              </div>
              <div
                v-if="meal.images.length != 0"
                :style="`background-image:url('${$store.state.def_path}/${meal.images[0].image}`"
                class="img"
                style=""
              ></div>

              <div
                v-if="meal.images.length == 0"
                :style="`background-image:url('${$store.state.def_path}/uploads/none_img.png`"
                class="img"
                style=""
              ></div>

              <div class="px-4 black--text">
                <h4
                  :style="{ color: `${info.secondaryColor}` }"
                  class="mt-2 name"
                >
                  {{ meal.name[$store.state.default_lang] }}
                </h4>
                <p
                  :style="{ color: `${info.secondaryColor}` }"
                  class="mb-2 desc"
                >
                  {{
                    meal.description[$store.state.default_lang]
                      ? meal.description[$store.state.default_lang]
                      : "..."
                  }}
                </p>
                <!--/img -->
              </div>
              <div
                :style="{
                  'border-color': `${info.secondaryColor}`,
                  opacity: '0.5',
                }"
                class="line"
              ></div>
              <p
                :style="{ color: `${info.secondaryColor}` }"
                class="product_card__price px-4"
              >
                <span class="me-2">
                  {{ pro_price[i][x] }}
                  ₪
                </span>
                <span
                  class="text-decoration-line-through"
                  v-if="item.sale && !meal.sale && !meal.more_price_sale"
                >
                  {{
                    meal.prices.length == 0 ? meal.price : meal.prices[0].price
                  }}
                  ₪
                </span>
                <span
                  class="text-decoration-line-through"
                  v-else-if="meal.more_price_sale && meal.sale"
                >
                  {{ meal.price }}
                  ₪
                </span>
                <span
                  class="text-decoration-line-through"
                  v-else-if="meal.more_price_sale"
                >
                  {{ meal.more_price }}
                  sec ₪
                </span>
                <v-chip
                  color="red lighten-3 white--text px-2"
                  v-if="meal.status == 2"
                  x-small
                  class="ms-auto"
                >
                  غير متوفر</v-chip
                >
              </p>
            </v-card>
            <!--/card -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "DefaultTheme",
  props: ["items", "info"],
  computed: {
    pro_price() {
      let pro_price = [];

      this.items.forEach((cat, x) => {
        pro_price[x] = [];
        cat.products.forEach((e, i) => {
          if (!cat.sale && e.sale == 0 && !e.more_price_sale) {
            e.prices.length == 0
              ? (pro_price[x][i] = e.price)
              : (pro_price[x][i] = e.prices[0].price);
          } else if (cat.sale && e.sale == 0 && e.more_price_sale) {
            let per = cat.sale / 100;
            let price = e.prices.length == 0 ? e.price : e.prices[0].price;
            let num = price * per;
            pro_price[x][i] = price - num;
          } else if (e.sale && !e.more_price_sale) {
            pro_price[x][i] = e.sale;
          } else if (!e.more_price_sale) {
            pro_price[x][i] = e.more_price_sale;
          }
        });
      });

      return pro_price;
    },
  },
  methods: {
    item_preview(meal, cat_id, cat_sale) {
      this.$emit("item_preview", {
        meal: meal,
        cat_id: cat_id,
        cat_sale: cat_sale,
      });
    },
  },
};
</script>
