<template>
  <div>
    <section
      :class="{ body: is_scroll }"
      class="categories d-flex px-0"
      id="cats_btn"
      :style="{
        'margin-top': !$store.getters.permissions.includes('video')
          ? '50px'
          : '10px',
      }"
    >
      <v-container class='d-flex pb-5 pt-0'>
        <v-chip
          @click="switch_cat(cat.id), get_win($event)"
          class="chip"
          v-for="(cat, i) in categories"
          :key="i"
          :outlined="cat.id != cat_id"
          :color="cat.id == cat_id ? 'primary' : 'secondary'"
        >
          {{ cat.category_name[$store.state.default_lang] }}
        </v-chip>
      </v-container>
    </section>

    <v-row style="margin-bottom: 100px">
      <v-col cols="6" sm="3" v-for="(meal, x) in products" :key="x">
        <v-card
          v-if="meal.status == 1 || meal.status == 2"
          class="product_card"
          @click="item_preview(meal, cat_id, cat_sale)"
          :color="info.cardColor"
        >
          <div
            v-if="cat_sale != 0 || !meal.sale || meal.more_price_sale != 0"
            class="sale primary body--text"
          >
            تخفيض
            <span
              v-if="
                cat_sale != 0 && meal.sale == 0 && meal.more_price_sale == 0
              "
            >
              <br />
              % {{ cat_sale }}
            </span>
            <span v-else-if="meal.more_price_sale == 0 && !meal.sale">
              <br />
              % {{ Math.floor(((meal.price - meal.sale) / meal.price) * 100) }}
            </span>
            <span v-else-if="meal.more_price_sale != 0">
              <br />
              %
              {{
                Math.floor(
                  ((meal.more_price - meal.more_price_sale) / meal.more_price) *
                    100
                )
              }}
            </span>
          </div>
          <div
            v-if="meal.images.length != 0"
            :style="`background-image:url('${$store.state.def_path}/${meal.images[0].image}`"
            class="img"
            style=""
          ></div>
          <div
            v-if="meal.images.length == 0"
            :style="`background-image:url('${$store.state.def_path}/uploads/none_img.png`"
            class="img"
            style=""
          ></div>

          <div class="px-4 black--text">
            <h4 :style="{ color: `${info.secondaryColor}` }" class="mt-2 name">
              {{ meal.name[$store.state.default_lang] }}
            </h4>
            <p :style="{ color: `${info.secondaryColor}` }" class="mb-2 desc">
              {{
                meal.description[$store.state.default_lang]
                  ? meal.description[$store.state.default_lang]
                  : "..."
              }}
            </p>
            <!--/img -->
          </div>
          <div
            :style="{
              'border-color': `${info.secondaryColor}`,
              opacity: '0.5',
            }"
            class="line"
          ></div>
          <p
            :style="{ color: `${info.secondaryColor}` }"
            class="product_card__price px-4"
          >
            <span class="me-2">
              {{ pro_price[x] }}
              ₪
            </span>
            <span
              class="text-decoration-line-through"
              v-if="
                cat_sale != 0 && meal.sale == 0 && meal.more_price_sale == 0
              "
            >
              {{ meal.prices.length == 0 ? meal.price : meal.prices[0].price }}
              ₪
            </span>
            <span
              class="text-decoration-line-through"
              v-else-if="meal.more_price_sale == 0 && !meal.sale"
            >
              {{ meal.price }}
              ₪
            </span>
            <span
              class="text-decoration-line-through"
              v-else-if="meal.more_price_sale != 0"
            >
              {{ meal.more_price }}
              ₪
            </span>
            <v-chip
              color="red lighten-3 white--text"
              v-if="meal.status == 2"
              x-small
              class="ms-auto px-2"
            >
              غير متوفر</v-chip
            >
          </p>
        </v-card>
        <!--/card -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ScrollTheme",
  props: ["info"],
  data() {
    return {
      products: [],
      cat_id: null,
      is_scroll: false,
      cat_sale: false,
      cat_per: 0,
    };
  },
  computed: {
    categories() {
      return this.$store.state.categories;
    },
    pro_price() {
      const pro_price = [];

      this.products.forEach((e, i) => {
        if (this.cat_sale == 0 && e.sale == 0 && e.more_price_sale == 0) {
          e.prices.length == 0
            ? pro_price.push(e.price)
            : pro_price.push(e.prices[0].price);
        } else if (
          this.cat_sale != 0 &&
          e.sale == 0 &&
          e.more_price_sale == 0
        ) {
          let per = this.cat_sale / 100;
          let price = e.prices.length == 0 ? e.price : e.prices[0].price;
          let num = price * per;
          pro_price.push(price - num);
        } else if (e.sale != 0 && e.more_price_sale == 0) {
          pro_price.push(e.sale);
        } else if (e.more_price_sale != 0) {
          pro_price.push(e.more_price_sale);
        }
      });

      return pro_price;
    },
  },
  methods: {
    get_win(ev) {
      console.log(ev);
    },
    switch_cat(id) {
      let cat = this.categories.find((e) => e.id == id);
      this.cat_id = id;
      this.cat_sale = cat.sale;
      this.products = Object.assign([], cat.products);
    },
    item_preview(meal, cat_id, cat_sale) {
      console.log(cat_sale);
      this.$emit("item_preview", {
        meal: meal,
        cat_id: cat_id,
        cat_sale: cat_sale,
      });
    },
  },
  created() {
    this.cat_id = this.categories[0].id;
    this.products = Object.assign([], this.categories[0].products);
    this.cat_sale = this.categories[0].sale;
    let vm = this;
    window.onscroll = function () {
      let mainOffset = document.querySelector(".v-main").offsetTop;
      let btns = document.getElementById("cats_btn");
      let docOffset = window.pageYOffset;
      if (mainOffset - 120 <= docOffset) {
        vm.is_scroll = true;
        btns.classList.add("fixed");
      } else {
        vm.is_scroll = false;
        btns.classList.remove("fixed");
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.product_card {
  width: 100% !important;
  position: relative;
}

.categories {
  position: relative;
  overflow-x: scroll;
  margin-top: 50px;
  &.fixed {
    width: 100%;
    position: fixed;
    right: 0;
    top: 64px;
    padding: 10px;
    z-index: 7;
  }
  .chip {
    text-align: center;
    display: block;
    margin-left: 10px;
    overflow: visible;
  }
  @media (max-width: 991px) {
    &::-webkit-scrollbar {
      width: 5px;
      height: 0px;
    }

    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background: #f0f0f0;
      border-radius: 10px;
      height: 5px;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #ddd;
    }
  }

  @media (min-width: 992px) {
    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background: #f0f0f0;
      border-radius: 10px;
      height: 5px;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #ddd;
    }
  }
}

.img {
  background-size: cover;
  width: 100%;
  background-position: center;
  height: 180px;
  @media (max-width: 767px) {
    height: 120px !important;
  }
}
</style>
