var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-bottom":"50px"}},_vm._l((_vm.items),function(item,i){return _c('section',{key:i,staticClass:"cat"},[(item.products.length != 0)?_c('div',[(item.products.length != 0)?_c('h3',{staticClass:"pt-4 pb-0 d-flex justify-space-between align-center"},[_c('span',{staticClass:"section-title pe-md-0",style:({ color: ("" + (_vm.info.primaryColor)) })},[_vm._v(_vm._s(item.category_name[_vm.$store.state.default_lang]))]),_c('router-link',{staticClass:"view-all",style:({ 'background-color': ("" + (_vm.info.primaryColor)) }),attrs:{"to":{
            name: 'category',
            params: {
              id: item.id,
              menu: _vm.$route.query.menu
                ? _vm.$route.query.menu
                : _vm.$route.params.menu,
            },
          }}},[_c('span',{staticClass:"body--text"},[_vm._v(_vm._s(_vm.$store.getters.trans("home", "home_view_all")))])])],1):_vm._e(),_c('div',{staticClass:"card_wrapper"},_vm._l((item.products),function(meal,x){return _c('div',{key:x},[(meal.status == 1 || meal.status == 2)?_c('v-card',{staticClass:"product_card",attrs:{"color":_vm.info.cardColor},on:{"click":function($event){return _vm.item_preview(meal, item.id, item.sale)}}},[(item.sale || meal.sale || meal.more_price_sale)?_c('div',{staticClass:"sale primary body--text"},[_vm._v(" تخفيض "),(
                  item.sale != 0 &&
                  meal.sale == 0 &&
                  meal.more_price_sale == 0
                )?_c('span',[_c('br'),_vm._v(" % "+_vm._s(item.sale)+" ")]):(meal.more_price_sale == 0 && !meal.sale)?_c('span',[_c('br'),_vm._v(" % "+_vm._s(Math.floor(((meal.price - meal.sale) / meal.price) * 100))+" ")]):(meal.more_price_sale != 0)?_c('span',[_c('br'),_vm._v(" % "+_vm._s(Math.floor( ((meal.more_price - meal.more_price_sale) / meal.more_price) * 100 ))+" ")]):_vm._e()]):_vm._e(),(meal.images.length != 0)?_c('div',{staticClass:"img",style:(("background-image:url('" + (_vm.$store.state.def_path) + "/" + (meal.images[0].image)))}):_vm._e(),(meal.images.length == 0)?_c('div',{staticClass:"img",style:(("background-image:url('" + (_vm.$store.state.def_path) + "/uploads/none_img.png"))}):_vm._e(),_c('div',{staticClass:"px-4 black--text"},[_c('h4',{staticClass:"mt-2 name",style:({ color: ("" + (_vm.info.secondaryColor)) })},[_vm._v(" "+_vm._s(meal.name[_vm.$store.state.default_lang])+" ")]),_c('p',{staticClass:"mb-2 desc",style:({ color: ("" + (_vm.info.secondaryColor)) })},[_vm._v(" "+_vm._s(meal.description[_vm.$store.state.default_lang] ? meal.description[_vm.$store.state.default_lang] : "...")+" ")])]),_c('div',{staticClass:"line",style:({
                'border-color': ("" + (_vm.info.secondaryColor)),
                opacity: '0.5',
              })}),_c('p',{staticClass:"product_card__price px-4",style:({ color: ("" + (_vm.info.secondaryColor)) })},[_c('span',{staticClass:"me-2"},[_vm._v(" "+_vm._s(_vm.pro_price[i][x])+" ₪ ")]),(item.sale && !meal.sale && !meal.more_price_sale)?_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(" "+_vm._s(meal.prices.length == 0 ? meal.price : meal.prices[0].price)+" ₪ ")]):(meal.more_price_sale && meal.sale)?_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(" "+_vm._s(meal.price)+" ₪ ")]):(meal.more_price_sale)?_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(" "+_vm._s(meal.more_price)+" sec ₪ ")]):_vm._e(),(meal.status == 2)?_c('v-chip',{staticClass:"ms-auto",attrs:{"color":"red lighten-3 white--text px-2","x-small":""}},[_vm._v(" غير متوفر")]):_vm._e()],1)]):_vm._e()],1)}),0)]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }