<template>
  <div class="header">
    <v-app-bar app color="body" flat height="75">
      <v-container class="py-0 fill-height d-block">
        <div class="d-flex justify-space-between align-center py-3">
          <h1
            v-if="$store.state.menu_loaded"
            class="header__logo headerColor--text"
          >
            {{ info ? info.store_name : "" }}
          </h1>
          <!--/ h1 -->
          <img
            v-if="$store.state.menu_loaded"
            style="height: 50px"
            :src="`${$store.state.def_path}/${info.logo}`"
          />
          <v-skeleton-loader v-if="!$store.state.menu_loaded" type="article" width="200" height="50"> </v-skeleton-loader>
          <v-skeleton-loader v-if="!$store.state.menu_loaded" type="avatar"> </v-skeleton-loader>
          <v-skeleton-loader v-if="!$store.state.menu_loaded" type="avatar"> </v-skeleton-loader>

          <!--/ logo -->
          <v-menu offset-y v-if="$store.state.menu_loaded">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                tile
                class="rounded-lg header-menu btn-drop-down black--text d-block"
                elevation="1"
                v-bind="attrs"
                v-on="on"
                icon
                color="headerColor"
              >
                <v-icon> translate </v-icon>
              </v-btn>
            </template>
            <v-list dense class="menu-no-active py-0">
              <v-list-item-group color="primary">
                <v-list-item
                  @click="change_lang(lang, i)"
                  v-for="(lang, i) in info.languages"
                  :key="i"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ lang.lang_name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <!--/menu -->
          </v-menu>
        </div>
      </v-container>
      <!--/ container -->
    </v-app-bar>
    <div
      class="video"
      v-if="
        this.$store.getters.permissions.includes('video') &&
        $route.name == 'home'
      "
    >
      <!-- <div class="video__layer primary lighten-1"></div>
      <div class="video__content">
        <h2 class="text-center">
          {{ info ? info.store_name : "" }}
        </h2>
        <v-btn @click="scroll_to_menu" class="video__btn" outlined color="white"
          >عرض المينيو</v-btn
        >
      </div> -->
      <video
        playsinline
        loop
        class="video__vd"
        :src="`${$store.state.def_path}/${info.video}`"
        autoplay
        muted
      ></video>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data: () => ({
    app_name: "مكان كافيه",
    lang_index: 0,
    links: [
      {
        text: "المينو",
        link: "home",
      },
      {
        text: "من نحن",
        link: "about",
      },
      {
        text: "ساعات الدوام",
        link: "time",
      },
      {
        text: "تواصل معنا",
        link: "contacts",
      },
    ],
  }),
  computed: {
    info() {
      return this.$store.state.store_info || null;
    },
    lang_name() {
      return this.$store.state.store_info.languages
        ? this.$store.state.store_info.languages[this.lang_index].lang_name
        : null;
    },
  },
  methods: {
    change_lang(ev, ind) {
      if (ev.dir == "ltr") {
        this.$vuetify.rtl = false;
      } else {
        this.$vuetify.rtl = true;
      }
      this.lang_index = ind;
      this.$store.state.default_lang = ev.locale;
    },
    scroll_to_menu() {
      let menu_sec = document.querySelector(".v-main").offsetTop - 120;
      window.scrollTo({
        top: menu_sec,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .header {
    &__logo {
      font-size: 25px;
    }
  }
}
.v-list--dense .v-list-item .v-list-item__title {
  font-size: 15px;
  line-height: 2rem;
}
.v-list-item--link:before {
  background-color: #fff;
}
.v-list-item.v-item--active {
  background: #ddd;
}
.v-list--nav.v-list--dense .v-list-item {
  margin-bottom: 0 !important;
  max-width: 100px;
  text-align: center;
}

.video {
  margin-top: 75px;
  //height: 100vh;
  &__vd {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  &__btn {
    border-width: 4px;
    margin: 100px auto 0;
    font-size: 20px;
    width: 200px;
    min-height: 60px;
    display: block;
    &.v-size--default {
      font-size: 20px;
      font-weight: bold;
    }
  }
  position: relative;
  &__layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.4;
  }
  &__content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    h2 {
      margin-top: calc(50vh - 100px);
      font-size: 40px;
      padding: 0 20px;
      color: #fff;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        right: 20px;
        bottom: -3px;
        width: 150px;
        height: 4px;
      }

    }
  }
}
</style>
