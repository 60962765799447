<template>
  <div class="mt-3">
    <h3 v-if="meal.notes.length != 0" class="black--text">{{$store.getters.trans("Product", "product_notes")}}</h3>
    <v-list dense v-if="meal.notes.length != 0" >
      <v-list-item class="px-0" v-for="(note, i) in meal.notes" :key="i">
        <v-list-item-content class="d-block">
            <div class="d-flex align-center">
              <img
            width="50"
            :src="`${$store.state.def_path}/${note.image}`"
            alt=""
            class="ml-3 mr-4"
          />
          {{
            $store.state.default_lang
              ? note.name[[$store.state.default_lang]]
              : ""
          }}
            </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!--/ mea;l notes -->
  </div>
</template>

<script>
export default {
  props: ["meal"],
  name: "Notes",
};
</script>
