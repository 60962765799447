<template>
  <div class="categories_theme">
    <v-row v-if="categories.length == 0">
      <v-col cols="6" sm="3" class="mb-5" v-for="(a, i) in 12" :key="i">
        <v-skeleton-loader type="card"> </v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row
      v-if="categories.length != 0"
      :style="{
        'margin-top': this.$store.getters.permissions.includes('video')
          ? '0'
          : '40px',
      }"
    >
      <v-col
        v-for="(category, i) in categories"
        :key="i"
        cols="6"
        sm="4"
        lg="3"
      >
        <v-card


          @click="
            $router.push(`/c/${$route.query.menu ? $route.query.menu : $route.params.menu}/${ category.id}`)
          "
          :color="info.cardColor"
        >
          <div
            v-if="category.image"
            :style="`background-image:url('${$store.state.def_path}/${category.image}`"
            class="cat_img"
            style=""
          ></div>

          <div
            v-else
            :style="`background-image:url('${$store.state.def_path}/uploads/none_img.png`"
            class="img"
            style=""
          ></div>

          <div class="py-3 px-4 black--text text-center">
            <h4 :style="{ color: `${info.secondaryColor}` }" class="name">
              {{ category.category_name[$store.state.default_lang] }}
            </h4>
          </div>
        </v-card>
        <!--/card -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CategoryTheme",
  props: ["info"],
  data() {
    return {};
  },
  computed: {
    categories() {
      return this.$store.state.categories;
    },
  },
};
</script>

<style lang="scss" scoped>
.categories_theme {
  margin-bottom: 100px;
}

::v-deep {
  .cat_img {
    background-size: cover;
    width: 100%;
    background-position: center;
    height: 180px;
    @media (max-width: 767px) {
      height: 140px;
    }
  }
  .name {
    line-height: 1.2;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      height: 30px;
    }
  }
}
</style>
