<template>
  <v-list class="mt-3" dense>
    <v-radio-group v-model="prices">
      <v-list-item
        @click="select_price(i, price)"
        class="px-0"
        v-for="(price, i) in meal.prices"
        :key="i"
      >
        <v-list-item-content class="font-weight-bold pr-3">
          <div class="text-end d-flex align-center">
            <v-radio
              v-if="has_order && meal.status != 2"
              class="d-inline-block checkbox"
              :value="i"
            ></v-radio>
            <span>
              {{
                $store.state.default_lang
                  ? price.name[[$store.state.default_lang]]
                  : ""
              }}</span
            >
            <span v-if="price.sale != 0" class="price d-inline-block"
              >{{ price.sale }} ₪
            </span>
            <span v-else-if="cat_sale != 0" class="price d-inline-block">
              {{ price.price - (cat_sale / 100) * price.price }} ₪
            </span>
            <span
              class="price d-inline-block"
              :class="{
                'text-decoration-line-through':
                  price.sale != 0 || cat_sale != 0,
              }"
            >
              {{ price.price }} ₪
            </span>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-radio-group>
  </v-list>
  <!--/ meal price -->
</template>

<script>
export default {
  props: ["meal", "cat_sale"],
  name: "Prices",
  data() {
    return {
      prices: 0,
    };
  },
  computed: {
    has_order() {
      return this.$store.getters.permissions.includes("orders");
    },
  },
  methods: {
    select_price(ind, price) {
      this.prices = ind;
      let final_price = 0;

      if(price.sale != 0) {
        final_price = price.sale;
      } else if(this.cat_sale != 0) {
           final_price = price.price - ((this.cat_sale / 100) * price.price);
      }else {
        final_price = price.price;
      }
      this.$emit("select_price", final_price);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input--selection-controls {
    margin-top: 0 !important;
  }
  .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
    margin-bottom: 0 !important;
  }
  .checkbox {
    position: relative;
    top: 6px;
  }
}
</style>
