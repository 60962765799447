import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import store from "../store/index";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  theme: {
    dark: store.state.darkMode,
    customProperties: true,
    themes: {
      light: {
        primary:'#000',
        secondary: "#000",
        body:"#000",
        headerColor:"#000",
        accent: "#8c9eff",
        error: "#b71c1c",
        success: "#55c0b4",
        grayText: "#717171",

      },
      dark: {
        primary: "#55c0b4",
        black: "#fff",
        success: "#55c0b4",
        secondary: "#0c1939",
      },
    },
  },
});
