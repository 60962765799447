<template>
  <v-container class="con" id="menu_section">
    <v-row v-if="items.length == 0">
      <v-col cols="6" sm="3" class="mb-5" v-for="(a, i) in 12" :key="i">
        <v-skeleton-loader type="card"> </v-skeleton-loader>
      </v-col>
    </v-row>

    <DefaultTheme
      v-if="items.length != 0 && info.theme == 'default'"
      :items="items"
      :info="info"
      @item_preview="item_preview($event.meal, $event.cat_id, $event.cat_sale)"
      
    ></DefaultTheme>

    <CategoryTheme
      v-if="info.theme == 'category' && items.length != 0"
      :info="info"
    ></CategoryTheme>

    <ScrollTheme
      v-if="info.theme == 'scroll' && items.length != 0"
      @item_preview="item_preview($event.meal, $event.cat_id, $event.cat_sale)"
      :info="info"
    ></ScrollTheme>

    <v-btn
      v-if="$store.state.cart.length != 0"
      height="50"
      :style="{ 'background-color': `${info.primaryColor}` }"
      class="pa-3 rounded-0 elevation-3 body--text font-weight-bold d-flex justify-space-between add_cart"
      @click="cart_dialog = true"
    >
      <div>
        <span class="badge white primary--text">{{ cart.length }}</span>
        {{ $store.getters.trans("cart", "cart_btn") }}
      </div>
      <div>₪ {{ total_cart }}</div>
    </v-btn>

    <Cart
      ref="cart"
      :info="info"
      @close_dialog="cart_dialog = false"
      :cart_dialog="cart_dialog"
      @fade_check_code="fade_check_code()"
      :order_side="order_side"
      @close_check_code="order_side_dialog = false"
      @order_side_fun="order_side_fun($event)"
    ></Cart>

    <Login
      @close_dialog="$store.state.login_dialog = false"
      :login_dialog="login_dialog"
    ></Login>

    <OrderSide
      @order_side="order_side_fun($event)"
      @code_found="code_found()"
      @close_dialog="order_side_dialog = false"
      :order_side_dialog="order_side_dialog"
    ></OrderSide>

    <Dialog
      v-if="info.theme == 'default' || info.theme == 'scroll'"
      :info="info"
      :meal="meal"
      :cat_id="cat_id"
      @close_dialog="dialog = false"
      :dialog="dialog"
      :cat_sale="cat_sale"
    ></Dialog>

    <v-snackbar timeout="1000" left v-model="$store.state.cart_alert">
      <p>Order Sent Successfully</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check</v-icon>
      </template>
    </v-snackbar>
  </v-container>
  <!-- dialog-bottom-transition -->
</template>

<script>
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/css/swiper.css";
import Dialog from "@/components/dialog.vue";
import Cart from "@/components/cart.vue";
import Login from "@/components/Login.vue";
import OrderSide from "@/components/Order_side";

// themes
import CategoryTheme from "@/components/themes/category";
import ScrollTheme from "@/components/themes/scroll";
import DefaultTheme from "@/components/themes/default";

export default {
  name: "home",
  data() {
    return {
      order_side: null,
      dialog: false,
      cart_dialog: false,
      login_dialog: false,
      order_side_dialog: false,
      cat_id: null,
      cat_sale: 0,
      meal: {
        prices: [],
        images: [],
        description: {},
        name: {},
      },
    };
  },
  components: {
    Dialog,
    Cart,
    Login,
    OrderSide,
    CategoryTheme,
    ScrollTheme,
    DefaultTheme,
  },
  watch: {},
  computed: {
    items() {
      return this.$store.state.categories || [];
    },
    info() {
      return this.$store.state.store_info || null;
    },
    cart() {
      return this.$store.state.cart;
    },
    total_item() {
      let totals = [];
      this.cart.forEach((e) => {
        let price = e.product_price;
        e.additions.forEach((s) => {
          price += s.price * s.quantity;
        });
        totals.push(price * e.quantity);
      });
      return totals;
    },
    total_cart() {
      let total = 0;
      this.total_item.forEach((e) => {
        total += e;
      });
      return total;
    },
  },
  methods: {

    item_preview(meal, cat_id, cat_sale) {
      this.meal = Object.assign({}, meal);
      this.cat_id = cat_id;
      this.cat_sale = cat_sale;
      console.log(cat_sale);
      this.dialog = true;
    },
    fade_check_code() {
      this.order_side_dialog = true;
    },
    order_side_fun(ev) {
      this.order_side = ev;
    },
    code_found() {
      this.order_side_dialog = false;
      setTimeout(() => {
        this.$refs.cart.send_order();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .card_wrapper {
    display: flex;
    overflow-x: scroll;
    padding: 20px 5px 20px 20px;
    @media (max-width: 767px) {
      padding: 20px 0px;
    }

    @media (max-width: 991px) {
      &::-webkit-scrollbar {
        width: 5px;
        height: 0px;
      }

      &::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: #f0f0f0;
        border-radius: 10px;
        height: 5px;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #ddd;
      }
    }

    @media (min-width: 992px) {
      &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: #f0f0f0;
        border-radius: 10px;
        height: 5px;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #ddd;
      }
    }
  }
  .sale {
    position: absolute;
    right: 0;
    top: -20px;
    width: 50px;
    height: 50px;
    border-radius: 50% !important;
    text-align: center;
    padding-top: 10px;
    font-size: 11px;
    font-weight: 600;
  }
  .product_card {
    position: relative;
    width: 220px;
    margin-left: 15px;
    box-shadow: rgb(0 0 0 / 6%) 0px 0px 0.125rem 0px,
      rgb(0 0 0 / 12%) 0px 0.125rem 0.125rem 0px !important;
    border-radius: 0.5rem !important;
    @media (max-width: 767px) {
      border-left: 1px solid rgb(0 0 0 / 6%);
      border-right: 1px solid rgb(0 0 0 / 6%);
      width: 150px;
      margin-left: 10px;
    }
  }
  .container.con {
    @media (max-width: 767px) {
      padding-left: 0 !important;
    }
  }
  .section-title {
    font-size: 1.5rem;
  }
  .view-all {
    text-align: center;
    display: inline-block;
    padding: 4px 15px;
    border-radius: 20px;
    font-size: 13px;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    span {
      opacity: 1;
      position: relative;
      z-index: 2;
    }
    // &:before {
    //   content: "";
    //   position: absolute;
    //   left: -1px;
    //   top: -1px;
    //   width: calc(100% + 2px);
    //   height: calc(100% + 2px);
    //   border-radius: 20px;
    //   background-color: rgba(0, 0, 0, 0.7);
    // }
    &:hover {
      .v-icon {
        color: #888 !important;
      }
      color: #888;
    }
  }
  .swiper.cats {
    padding-left: 40px;
    direction: rtl;
    &--sec {
      padding-left: 80px;
    }
    .category {
      text-decoration: none;
      border-radius: 5px;
      border: 4px solid #ddd;
      cursor: pointer;
      &--active {
        border-color: #ea9e57;
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #ea9e57;
      color: #fff;
      top: calc(50% - 10px);
      left: 5px;
      &:after {
        font-size: 16px;
      }
      &.swiper-button-disabled {
        display: none !important;
      }
    }
    .swiper-button-prev {
      left: auto;
      right: 5px;
    }
  }
  .price {
    display: inline-block;
    border-radius: 20px;
    color: #fff;
    padding: 0px 8px;
    line-height: 1.4;
    font-size: 14px;
  }
  .line {
    border-top: 0.0625rem dashed rgba(32, 33, 37, 0.12);
  }
  .desc {
    font-size: 15px;

    white-space: wrap;
    line-height: 1.5em;
    height: 3em;   
    overflow: hidden;
    word-break: break-all;
    @media (max-width: 767px) {
      font-size: 10px;
    }
  }
  .name {
    white-space: wrap;
    line-height: 1.5em;
    height: 3em;   
    word-break: break-all;
    overflow: hidden;

    @media (max-width: 767px) {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .img {
    background-size: cover;
    width: 100%;
    background-position: center;
    height: 160px;
    @media (max-width: 767px) {
      height: 100px;
    }
  }

  .product_card__price {
    font-size: 0.75rem;
    line-height: 1rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 400;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 2rem;
    // border-top: 0.0625rem dashed rgba(32, 33, 37, 0.12);
    color: rgba(32, 33, 37, 0.64);
    padding: 0px 1rem;
  }

  .add_cart {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 20;
    @media (min-width: 768px) {
      width: 400px;
      right: calc(50% - 200px);
    }
  }
  .badge {
    width: 18px;
    height: 18px;
    line-height: 19px;
    display: inline-block;
    border-radius: 50%;
    font-size: 11px;
    position: relative;
    top: -2px;
    margin-left: 2px;
  }
}
</style>
