<template>
  <div>

    <v-dialog
    style="height: auto !important"
    max-width="500"
    :value="dialog"
    transition="dialog-bottom-transition"
    content-class="vdialognew"
    @input="dialog_closed($event), reset_cart_item()"
    scrollable
  >
    <v-card class="meal rounded-xl" elevation="0">
      <!-- <pre>
      {{ meal }}
    </pre> -->
      <v-card-title class="pa-0">
        <v-btn
          class="close"
          @click="$emit('close_dialog'), reset_cart_item()"
          fab
          color="white"
          small
        >
          <v-icon color="black">close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-0">
        <div
          v-if="meal.images.length == 0"
          :style="`background-image:url('${$store.state.def_path}/none_img.png`"
          class="meal__bg"
        ></div>
        <v-carousel
          :show-arrows="show_arrow"
          height="auto"
          hide-delimiters
          v-model="model"
        >
          <v-carousel-item v-for="(img, i) in meal.images" :key="i">
            <div
              v-if="meal.images.length != 0"
              :style="`background-image:url('${$store.state.def_path}/${img.image}')`"
              class="meal__bg"
            ></div>
            <div
              v-else-if="meal.images.length == 1"
              :style="`background-image:url('../assets/none_img.png')`"
              class="meal__bg"
            ></div>
          </v-carousel-item>
        </v-carousel>
        <div class="pt-5 px-4 pb-10">
          <!--/ card title -->
          <h2 class="meal__name primary--text">
            {{ meal.name[$store.state.default_lang] }}
            <v-chip
              color="red lighten-3 white--text"
              v-if="meal.status == 2"
              x-small
              class="ms-auto"
              style="font-weight: 400 !important"
            >
              غير متوفر حالياً</v-chip
            >
          </h2>
          <!--/ meal name -->

          <h5 v-if="meal.prices.length == 0" class="price mt-3">
            <span v-if="cat_sale == 0 && meal.sale == 0">
              ₪ {{ meal.price }}
            </span>

            <div v-else-if="cat_sale != 0 && meal.sale == 0">
              <span>
                ₪
                {{ meal.price - (cat_sale / 100) * meal.price }}
              </span>
            </div>

            <div v-else-if="!meal.sale">
              <span class="ms-1">
                ₪
                {{ meal.sale }}
              </span>
              <span class="text-decoration-line-through ms-1">
                ₪
                {{ meal.price }}
              </span>
            </div>
          </h5>

          <p class="mt-4 grayText--text meal__desc">
            {{ meal.description[$store.state.default_lang] }}
          </p>
          <!--/ meal description -->
          <Prices
            @select_price="select_price($event)"
            :meal="meal"
            v-if="meal.prices.length != 0"
            :cat_sale="cat_sale"
          ></Prices>
          <FreeAdditions
          :has_order="has_order"
            @add_used="add_used($event)"
            @add_cart="add_cart($event)"
            @remove_cart="remove_cart($event)"
            :meal="meal"
            v-if="has_additions"
          ></FreeAdditions>
          <Notes :meal="meal" v-if="has_notes"></Notes>
          <Additions
            :has_order="has_order"
            :cart_item="cart_item"
            @add_cart="add_cart($event)"
            @remove_cart="remove_cart($event)"
            :additions="cart_item.additions"
            v-if="has_additions"
            :meal="meal"
          ></Additions>
        </div>
      </v-card-text>
      <v-card-actions v-if="has_order && meal.status != 2" class="pb-4">
        <v-btn-toggle width="30%" class="elevation-0">
          <v-btn
            :disabled="cart_item.quantity == 1"
            class="btn-remove border-none lighten-4 primary primary--text px-2"
            @click="dec_quan()"
          >
            <v-icon color="primary">remove</v-icon>
          </v-btn>

          <v-btn
            width="30"
            class="btn-quan px-1 border-none subtitle-1 font-weight-bold lighten-4 primary primary--text"
          >
            {{ cart_item.quantity }}
          </v-btn>
          <v-btn
            class="px-2 btn-add lighten-4 primary primary--text border-none"
            @click="inc_qun()"
          >
            <v-icon color="primary">add</v-icon>
          </v-btn>
        </v-btn-toggle>

        <v-spacer></v-spacer>
        <v-btn
          @click="add_to_cart()"
          color="primary"
          height="50"
          class="pa-3 elevation-0 body--text rounded font-weight-bold d-flex justify-space-between btn_add_cart ms-3"
        >
          <span>{{
            $store.getters.trans("Product", "product_add_cart_btn")
          }}</span>
          <span>₪ {{ total }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import Prices from "@/components/product/prices";
import FreeAdditions from "@/components/product/FreeAdditions";
import Notes from "@/components/product/notes";
import Additions from "@/components/product/additions";
export default {
  props: ["dialog", "meal", "info", "cat_id", "cat_sale"],
  name: "Dialog",
  data() {
    return {
      prices: 0,
      show_arrow: false,
      model: 0,
      cart_item: {
        product_name: {},
        product_price: 0,
        item_img: null,
        product_id: null,
        cat_id: null,
        price_id: null,
        additions: [],
        free_additions: [],
        quantity: 1,
      },
    };
  },
  watch: {
    dialog(ev) {
      if (ev) {
        if (
          !this.cat_sale &&
          !this.meal.sale &&
          !this.meal.more_price_sale
        ) {
          this.cart_item.product_price =
            this.meal.prices.length != 0
              ? this.meal.prices[0].price
              : this.meal.price;
        } else if (
          this.cat_sale != 0 &&
          this.meal.sale == 0 &&
         !this.meal.more_price_sale
        ) {
          if (!this.meal.prices.length) {
            let total_per =
              this.meal.price - (this.cat_sale / 100) * this.meal.price;
            this.cart_item.product_price = total_per;
          } else {
            this.cart_item.product_price =
              this.meal.prices[0].price -
              (this.cat_sale / 100) * this.meal.prices[0].price;
          }
        } else if (!this.meal.sale && this.meal.more_price_sale == 0) {
          this.cart_item.product_price = this.meal.sale;
        } else if (this.meal.more_price_sale != 0) {
          this.cart_item.product_price = this.meal.more_price_sale;
        }

        this.cart_item.price_id =
          this.meal.prices.length != 0 ? this.meal.prices[0].id : null;
        this.cart_item.product_name = this.meal.name;
        this.cart_item.product_id = this.meal.id;
        this.cart_item.store_id = this.store_id;
        this.cart_item.cat_id = this.cat_id;
        this.meal.free_additions.forEach((e) => {
          this.cart_item.free_additions.push({
            name: e.name,
            is_free: true,
            id: e.id,
            price: e.price,
            used: true,
          });
        });

        if (this.meal.images.length <= 1) {
          this.show_arrow = false;
        } else {
          this.show_arrow = true;
        }
      } else {
        this.reset_cart_item();
      }
    },
  },
  components: {
    Prices,
    FreeAdditions,
    Notes,
    Additions,
  },
  computed: {
    has_order() {
      return this.$store.getters.permissions.includes("orders");
    },
    has_additions() {
      return this.$store.getters.permissions.includes("additions");
    },
    has_notes() {
      return this.$store.getters.permissions.includes("notes");
    },
    store_id() {
      return this.info.id || null;
    },
    total() {
      let total_add = 0;
      this.cart_item.additions.forEach((e) => {
        total_add += e.price * e.quantity;
      });
      return (
        (this.cart_item.product_price + total_add) * this.cart_item.quantity
      );
    },
  },
  methods: {
    inc_qun() {
      this.cart_item.quantity++;
    },
    dec_quan() {
      if (this.cart_item.quantity != 1) {
        this.cart_item.quantity--;
      }
    },
    dialog_closed(ev) {
      if (!ev) {
        this.$emit("close_dialog");
        this.reset_cart_item();
      }
    },
    select_price(ev) {
      this.cart_item.product_price = ev;
    },
    add_cart(ev) {
      this.cart_item[ev.type].push(ev.item);
    },
    remove_cart(ev) {
      if (ev.type == "multiple") {
        let find_add = this.cart_item[ev.kind].findIndex((e) => e.id == ev.id);
        this.cart_item[ev.kind].splice(find_add, 1);
      } else {
        let find_add = this.cart_item.additions.findIndex((e) => e.id != ev.id);
        this.cart_item.additions.splice(find_add, 1);
      }
    },
    add_to_cart() {
      if (this.$store.getters.loggedin) {
        this.inset_into_cart();
      } else {
        this.$store.state.login_dialog = true;
      }
    },
    reset_cart_item() {
      const default_item = {
        product_name: {},
        product_price: 0,
        product_id: null,
        price_id: null,
        additions: [],
        free_additions: [],
        quantity: 1,
        item_img: null,
        cat_id: null,
      };
      this.cart_item = Object.assign({}, default_item);
      this.meal.additions.forEach((e) => {
        e.adds.forEach((s) => {
          s.checked = false;
          s.quantity = 1;
        });
      });
      this.meal.free_additions.forEach((e) => {
        e.checked = false;
      });
    },
    add_used(ev) {
      let item_index = this.cart_item.free_additions.findIndex(
        (e) => e.id == ev.item.id
      );
      this.cart_item.free_additions[item_index].used = ev.is_used;
    },
    inset_into_cart() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "cart",
            method: "post",
          },
          data: {
            item: this.cart_item,
            total: this.total,
          },
        })
        .then((res) => {
          this.$store.dispatch("get_cart");
          this.$emit("close_dialog");
          this.reset_cart_item();
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },

  mounted() {
    let vm = this;
    window.onload = function () {
      if (window.innerWidth <= 991) {
        vm.show_arrow = false;
      }
    };
    window.onresize = function () {
      if (window.innerWidth <= 991) {
        vm.show_arrow = false;
      } else {
        vm.show_arrow = true;
      }
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  // .v-dialog:not(.v-dialog--fullscreen) {
  //   max-height: fit-content;
  // }
  .remove_right {
    border-right: 0 !important;
  }
  .remove_left {
    border-left: 0 !important;
  }
  .border-none {
    border: 0 !important;
  }
  .btn_add_cart {
    width: calc(100% - 144px);
  }
  .btn-quan,
  .btn-remove,
  .btn-add {
    // border-left: 0 !important;
    // border-right: 0 !important;
    min-width: 44px !important;
  }
  .close {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 5;
  }
  .price {
    display: inline-block;
    border-radius: 20px;
    font-weight: bold;
    padding: 0px 8px;
    line-height: 1.4;
    font-size: 18px;
  }
  .meal {
    overflow: hidden;
    position: relative;
    &__bg {
      height: 330px;
      width: 100%;
      background-size: cover;
      background-position: center;
      @media (max-width: 767px) {
        height: 280px;
      }
    }
    &__name {
      font-size: 26px;
    }
    &__price {
      font-size: 18px;
    }
    &__desc {
      line-height: 2;
      @media (max-width: 991px) {
        font-size: 14px;
        line-height: 1.8;
        // padding-bottom:100px
      }
    }
  }
  .v-list-item--dense,
  .v-list--dense .v-list-item {
    height: 30px;
    &:not(:last-of-type) {
      border-bottom: 1px solid #f0f0f0;
    }
    .price {
      font-size: 13px;
    }
  }
  .v-dialog {
    border-radius: 21px;
    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(206, 204, 204);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #ddd;
    }
    @media (max-width: 767px) {
      margin: 0;
      margin-top: auto;
      border-radius: 0;
      .v-card {
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }
}
</style>
