<template>
  <v-container class="mb-10 cat">
    <h3 class=" category_header body">
      <v-container class="d-flex justify-space-between align-center py-4">
        <span class="section-title" :style="{ color: `${info.primaryColor}` }">{{
        !category.empty
          ? category.cat.category_name[$store.state.default_lang]
          : ""
      }}</span>

      <router-link
        :style="{ 'background-color': `${info.primaryColor}` }"
        class="view-all"
        :to="`/${this.$route.params.menu}`"
      >
        <span> <v-icon small color="body">arrow_back</v-icon></span>
      </router-link>
      </v-container>
    </h3>

    <v-row class="cat_section" v-if="category.cat.length == 0">
      <v-col cols="6" sm="3" class="mb-5" v-for="(a, i) in 12" :key="i">
        <v-skeleton-loader type="card"> </v-skeleton-loader>
      </v-col>
    </v-row>

    <section class="cat_section">

      <v-row  class="mb-10" v-if="category.cat.length != 0">
      <v-col
        v-for="(meal, i) in category.cat.products"
        :key="i"
        cols="6"
        sm="4"
        md="3"
        v-show="meal.status != 0"
      >
        <v-card
          v-if="meal.status == 1 || meal.status == 2"
          class="product_card"
          @click="item_preview(meal, category.cat.id, category.cat.sale)"
          :color="info.cardColor"
        >
        <pre>
          {{  category.cat.sale }} aa
        </pre>
          <div
            v-if="
              category.cat.sale||
              meal.sale ||
              meal.more_price_sale
            "
            class="sale primary body--text"
          >
            تخفيض
            <span
              v-if="
                category.cat.sale &&
                !meal.sale &&
                !meal.more_price_sale
              "
            >
              <br />
              % {{ category.sale }}
            </span>
            <span v-else-if="!meal.more_price_sale && !meal.sale">
              <br />
              %
              {{ Math.floor(((meal.price - meal.sale) / meal.price) * 100) }}
            </span>
            <span v-else-if="!meal.more_price_sale">
              <br />
              %
              {{
                Math.floor(
                  ((meal.more_price - meal.more_price_sale) / meal.more_price) *
                    100
                )
              }}
            </span>
          </div>
          <!-- :style="`background-image:url('${$store.state.def_path}/${meal.images[0].image}`" -->
          <div
            v-if="meal.images.length != 0"

            :style="`background-image:url('${$store.state.def_path}/${meal.images[0].image}`"
            class="img"
            style=""
          ></div>

          <div
            v-if="meal.images.length == 0"

            
            :style="`background-image:url('${$store.state.def_path}/uploads/none_img.png`"
            class="img"
            style=""
          ></div>

          <div class="px-4 black--text">
            <h4 :style="{ color: `${info.secondaryColor}` }" class="mt-2 name">
              {{ meal.name[$store.state.default_lang] }}
            </h4>
            <p :style="{ color: `${info.secondaryColor}` }" class="mb-2 desc">
              {{
                meal.description[$store.state.default_lang]
                  ? meal.description[$store.state.default_lang]
                  : "..."
              }}
            </p>
            <!--/img -->
          </div>
          <div
            :style="{
              'border-color': `${info.secondaryColor}`,
              opacity: '0.5',
            }"
            class="line"
          ></div>
          <p
            :style="{ color: `${info.secondaryColor}` }"
            class="product_card__price px-4"
          >
            <span class="me-2">
              {{ pro_price[i] }}
              ₪
            </span>
            <pre>
            </pre>
            <span
              class="text-decoration-line-through"
              v-if="
                category.cat.sale &&
                meal.sale &&
                meal.more_price_sale
              "
            >
              {{ meal.prices.length == 0 ? meal.price : meal.prices[0].price }}
              ₪  
            </span>
            <span
              class="text-decoration-line-through"
              v-else-if="meal.more_price_sale && meal.sale"
            >
              {{ meal.price }}
              ₪ 
            </span>
            <span
              class="text-decoration-line-through"
              v-else-if="meal.more_price_sale"
            >
              {{ meal.more_price }}
              ₪ 
            </span>
            <v-chip
              color="red lighten-3 white--text"
              v-if="meal.status == 2"
              x-small
              class="ms-auto px-2"
            >
              غير متوفر</v-chip
            >
          </p>
        </v-card>
        <!--/card -->
      </v-col>
    </v-row>

    </section>

    <v-btn
      v-if="$store.state.cart.length != 0"
      height="50"
      :style="{ 'background-color': `${info.primaryColor}` }"
      class="pa-3 rounded-0 elevation-3 body--text font-weight-bold d-flex justify-space-between add_cart"
      @click="cart_dialog = true"
    >
      <div>
        <span class="badge white primary--text">{{ cart.length }}</span>
        {{ $store.getters.trans("cart", "cart_btn") }}
      </div>
      <div>₪ {{ total_cart }}</div>
    </v-btn>

    <Cart
      ref="cart"
      :info="info"
      @close_dialog="cart_dialog = false"
      :cart_dialog="cart_dialog"
      @fade_check_code="fade_check_code()"
      :order_side="order_side"
      @close_check_code="order_side_dialog = false"
      @order_side_fun="order_side_fun($event)"
    ></Cart>

    <Login
      @close_dialog="login_dialog = false"
      :login_dialog="login_dialog"
    ></Login>

    <OrderSide
      @order_side="order_side_fun($event)"
      @code_found="code_found()"
      @close_dialog="order_side_dialog = false"
      :order_side_dialog="order_side_dialog"
    ></OrderSide>

    <Dialog
      :info="info"
      :meal="meal"
      @close_dialog="dialog = false"
      :dialog="dialog"
      :cat_id="cat_id"
      :cat_sale="cat_sale"
    ></Dialog>

    <v-snackbar timeout="1000" left v-model="$store.state.cart_alert">
      <p>Order Sent Successfully</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check</v-icon>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Dialog from "@/components/dialog.vue";
import Cart from "@/components/cart.vue";
import Login from "@/components/Login.vue";
import OrderSide from "@/components/Order_side";

export default {
  name: "category",
  data() {
    return {
      order_side: null,
      dialog: false,
      cart_dialog: false,
      login_dialog: true,
      order_side_dialog: false,
      cat_id: null,
      cat_sale: 0,
      meal: {
        prices: [],
        images: [],
        description: {},
        name: {},
      },
    };
  },
  computed: {
    category() {
      return this.$store.getters.category(this.$route.params.id) || [];
    },
    items() {
      return this.$store.state.categories || [];
    },
    info() {
      return this.$store.state.store_info || null;
    },
    cart() {
      return this.$store.state.cart;
    },
    total_item() {
      let totals = [];
      this.cart.forEach((e) => {
        let price = e.product_price;
        e.additions.forEach((s) => {
          price += s.price;
        });
        totals.push(price * e.quantity);
      });
      return totals;
    },
    total_cart() {
      let total = 0;

      this.total_item.forEach((e) => {
        total += e;
      });
      return total;
    },
    pro_price() {
      let pro_price = [];
      let cat = this.category.cat;
      this.category.cat.products.forEach((e, i) => {
        if (!cat.sale && e.sale == 0 && !e.more_price_sale) {
          e.prices.length == 0
            ? (pro_price[i] = e.price)
            : (pro_price[i] = e.prices[0].price);
        } else if (cat.sale && !e.sale && !e.more_price_sale) {
          let per = cat.sale / 100;
          let price = e.prices.length == 0 ? e.price : e.prices[0].price;
          let num = price * per;
          pro_price[i] = price - num;
        } else if (!e.sale  && !e.more_price_sale) {
          pro_price[i] = e.sale;
        } else if (!e.more_price_sale) {
          pro_price[i] = e.more_price_sale;
        }
      });

      return pro_price;
    },
  },
  methods: {
    get_cat(cat_index) {
      this.categories.forEach((e, i) => {
        if (i != cat_index) {
          this.categories[i].prop = false;
        }
      });
      this.categories[cat_index].prop = true;
    },
    item_preview(meal, cat_id, cat_sale) {
      this.meal = Object.assign({}, meal);
      this.cat_id = cat_id;
      this.cat_sale = cat_sale;
      console.log(cat_sale);
      this.dialog = true;
    },
    fade_check_code() {
      this.order_side_dialog = true;
    },
    order_side_fun(ev) {
      this.order_side = ev;
    },
    code_found() {
      this.order_side_dialog = false;
      setTimeout(() => {
        this.$refs.cart.send_order();
      });
    },
  },
  components: {
    // Swiper,
    // SwiperSlide,
    Dialog,
    Cart,
    Login,
    OrderSide,
  },
  created() {
    console.log(this.$route.params)
    if (!this.$route.params.id) {
      this.$router.push(`/${this.$route.params.menu}`);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
.cat_section {
  margin-top:120px;
}
  .category_header {
    position:fixed;
    left:0;
    top:75px;
    width:100%;
    z-index: 1000;
  }
  .section-title {
    font-size: 1.5rem;
  }
  .line {
    border-top: 0.0625rem dashed rgba(32, 33, 37, 0.12);
  }
  .view-all {
    text-align: center;
    display: inline-block;
    padding: 4px 15px;
    border-radius: 20px;
    font-size: 13px;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    span {
      opacity: 1;
      position: relative;
      z-index: 2;
    }
  }
  .sale {
    position: absolute;
    right: -5px;
    top: -20px;
    width: 57px;
    height: 57px;
    border-radius: 50% !important;
    text-align: center;
    padding-top: 10px;
    font-size: 12px;
    font-weight: 600;
  }
  .price {
    display: inline-block;
    border-radius: 20px;
    color: #fff;
    padding: 0px 8px;
    line-height: 1.4;
    font-size: 14px;
  }
  .desc {
    font-size: 15px;

    white-space: wrap;
    line-height: 1.5em;
    height: 3em;   
    overflow: hidden;
    word-break: break-all;
    @media (max-width: 767px) {
      font-size: 10px;
    }
  }
  .name {
    white-space: wrap;
    line-height: 1.5em;
    height: 3em;   
    word-break: break-all;
    overflow: hidden;

    @media (max-width: 767px) {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .img {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 160px;
    @media (max-width: 767px) {
      height: 120px;
    }
  }
  .product_card {
    position: relative;
    box-shadow: rgb(0 0 0 / 6%) 0px 0px 0.125rem 0px,
      rgb(0 0 0 / 12%) 0px 0.125rem 0.125rem 0px !important;
    border-radius: 0.5rem !important;
    @media (max-width: 767px) {
      border-left: 1px solid rgb(0 0 0 / 6%);
      border-right: 1px solid rgb(0 0 0 / 6%);
    }
  }
  .product_card__price {
    font-size: 0.75rem;
    line-height: 1rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 400;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 2rem;
    // border-top: 0.0625rem dashed rgba(32, 33, 37, 0.12);
    color: rgba(32, 33, 37, 0.64);
    padding: 0px 1rem;
  }
  .add_cart {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    @media (min-width: 768px) {
      width: 400px;
      right: calc(50% - 200px);
    }
  }
  .badge {
    width: 18px;
    height: 18px;
    line-height: 19px;
    display: inline-block;
    border-radius: 50%;
    font-size: 11px;
    position: relative;
    top: -2px;
    margin-left: 2px;
  }

}
</style>
