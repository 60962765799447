<template>
  <v-app>
    <AppHeader v-if="$route.name != 'notfound'"></AppHeader>
    <v-main class="body" :style="{'padding-top' : $store.getters.permissions.includes('video')  ? '0!important' : '50px!important'}">
      <router-view />
    </v-main>


  </v-app>

</template>
<style scoped></style>
<script>
import AppHeader from "./layout/header.vue";

export default {
  name: "App",
  data: () => ({
    page_keys: {
      hide_arrow: "",
      pages: [],
    },
    login_dialog:true
  }),
  components: {
    AppHeader,
  },
  watch: {
    "$store.state.store_info"(ev) {
      const favicon = document.getElementById("favicon");
      favicon.href = `${this.$store.state.def_path}/${ev.logo}`;
      document.title =  ev.store_name;
      if (ev.languages[0].dir == "ltr") {
        this.$vuetify.rtl = false;
      } else {
        this.$vuetify.rtl = true;
      }
      this.$vuetify.theme.themes.light.primary = ev.primaryColor;
      this.$vuetify.theme.themes.light.secondary = ev.secondaryColor;
      this.$vuetify.theme.themes.light.body = ev.bodyColor;
      this.$vuetify.theme.themes.light.headerColor = ev.headerColor;

    },
  },
  computed:{
    info() {
      return this.$store.state.store_info || null;
    },
  },
  created() {
    if(this.$store.getters.loggedin) {
      let config = {
        method: "get",
        url: `cart/${this.$route.query.menu || this.$route.params.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.$store.state.token}`,
        },
      };
      this.axios(config)
        .then((res) => {
          this.$store.state.cart = Object.assign([], res.data.data);
          console.log(res);
        })
        .catch((err) => {
            if(err.response.status == 401) {
              localStorage.removeItem("token_menu_customer");
            }
        });
    }
  },
  beforeMount() {
    this.$store.dispatch("get_menu");
  },
};
</script>
