<template>
  <v-dialog
    max-width="500"
    scrollable
    v-model="$store.state.login_dialog"
    persistent
  >
    <v-card>
      <!-- <v-card-title>يرجي التسجيل حتي تتمكن من اضافة طلبك</v-card-title>
      <v-divider></v-divider> -->
      <v-alert v-if="step != 4" color="#feebcb" class="text-center">
        <small>
          {{
            $store.getters.trans("Customer_login", "customer_login_alert")
          }}</small
        >
      </v-alert>
      <v-card-text class="px-10 pt-5 pb-0">
        <v-form ref="form">
          <v-stepper elevation="0" alt-labels v-model="step">
            <v-stepper-content class="pa-0" step="1">
              <v-text-field
                :error-messages="phone_err ? inp_required : ''"
                v-model="customer.phone"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{
                    $store.getters.trans(
                      "Customer_login",
                      "customer_login_phone"
                    )
                  }}
                </template>
              </v-text-field>
              <!--/ phone number -->
            </v-stepper-content>
            <!--/step 1-->
            <v-stepper-content class="pa-0" step="2">
              <v-text-field v-model="customer.name">
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{
                    $store.getters.trans(
                      "Customer_login",
                      "customer_login_name"
                    )
                  }}
                </template>
              </v-text-field>
            </v-stepper-content>
            <!--/step 2-->

            <v-stepper-content class="pa-0" step="3">
              <h2 class="text-center mb-5">
                {{
                  $store.getters.trans(
                    "Customer_login",
                    "customer_login_confirmation_code"
                  )
                }}
              </h2>
              <h3 v-if="code_err" class="error--text text-center mb-2">
                {{ code_err_msg }}
              </h3>
              <v-otp-input
                v-model="customer.confirmation"
                :error="code_err"
                style="direction: ltr !important; width: 250px; margin: 0 auto"
                length="4"
              ></v-otp-input>
              <div class="d-flex justify-center align-center my-3">
                <h2
                  class="font-weight-bold"
                  :class="[
                    { 'red--text': count != 0 },
                    { 'grey--text': count == 0 },
                  ]"
                >
                  {{ count }}
                </h2>

                <v-btn
                  @click="login()"
                  :disabled="count != 0"
                  small
                  class="mx-3"
                  color="primary"
                >
                  {{ $store.getters.trans("Customer_login", "ressend_btn") }}
                  <v-icon>refresh</v-icon>
                </v-btn>
              </div>
            </v-stepper-content>
            <!--/step 3 -->

            <v-stepper-content class="pa-0 pt-5 text-center" step="4">
              <img src="@/assets/success.png" alt="" />
              <h2 class="text-center pt-4 pb-2">
                {{
                  $store.getters.trans(
                    "Customer_login",
                    "customer_login_success_msg"
                  )
                }}
              </h2>
            </v-stepper-content>
          </v-stepper>
        </v-form>
      </v-card-text>
      <v-card-actions class="px-10 pb-10">
        <v-btn
          width="50%"
          elevation="0"
          v-if="step != 4"
          @click="close_login()"
          height="50"
          class="lighten-4 primary primary--text close_login font-weight-bold"
        >
          <span>
            {{ $store.getters.trans("Customer_login", "customer_login_close") }}
          </span>
        </v-btn>
        <v-btn
          width="48%"
          elevation="0"
          height="50"
          v-if="step != 4"
          @click="next_step()"
          :color="info.primaryColor"
          class="body--text"
          :disabled="check_loading"
          :loading="check_loading"
        >
          {{
            $store.getters.trans("Customer_login", "customer_login_next")
          }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Login",
  props: ["login_dialog"],
  data() {
    return {
      check_loading: false,
      code_err: "",
      step: 1,
      count: 0,
      customer: {
        // phone: "0528733713",
        phone: "",
        city: "",
        name: "",
        confirmation: "",
      },
      btn_text: "Next",
      phone_err: false,
      code_err: false,
      code: "1234",
      interval: null,
    };
  },
  watch: {
    step(val) {
      if (val == 4) {
        let vm = this;
        this.interval = setTimeout(() => {
          vm.$store.state.login_dialog = false;
        }, 1000);
      }
    },
  },
  computed: {
    inp_required() {
      return this.$store.getters.trans(
        "Customer_login",
        "customer_login_required_input"
      );
    },
    info() {
      return this.$store.state.store_info || null;
    },
  },
  methods: {
    next_step() {
      if (this.step == 1) {
        if (this.customer.phone) {
          // if customer phone exists
          this.login(); // check if customer exists
          this.phone_err = false;
        } else {
          this.phone_err = true;
        }
      } else if (this.step == 2) {
        this.signup();
      } else if (this.step == 3) {
        this.code_err = false;
        this.check_code();
      }
    },
    count_down() {
      this.interval = setInterval(() => {
        this.count--;
        if (this.count == 0) {
          clearInterval(this.interval);
        }
      }, 1000);
    },
    check_code() {
      if (this.count != 0) {
        this.check_loading = true;
        var config = {
          method: "post",
          url: "phone_code",
          headers: {
            Accept: "application/json",
          },
          data: {
            phone: this.customer.phone,
            code: this.customer.confirmation,
          },
        };
        this.axios(config)
          .then((res) => {
            const token = res.data.token;
            const user = res.data.user;
            localStorage.setItem("token_menu_customer", token);
            localStorage.setItem("user_menu_customer", JSON.stringify(user));
            this.$store.commit("login", token);
            this.$store.commit("user_menu_customer", user);
            this.step = 4;
            this.check_loading = false;
          })
          .catch((err) => {
            this.check_loading = false;
            if (err.response.data.data.code == "not_found") {
              this.code_err_msg = this.$store.getters.trans(
                "Customer_login",
                "code_err"
              );
            } else {
              this.code_err_msg = this.$store.getters.trans(
                "Customer_login",
                "code_expired"
              );
            }
            this.code_err = true;
          });
      }
    },
    login() {
      if (this.step == 1) {
        this.check_loading = true;
      }
      this.$store
        .dispatch("login", {
          phone: this.customer.phone,
          store_name: this.$store.state.store_info.store_name,
        })
        .then((res) => {
          this.step = 3;
          this.check_loading = false;
          this.count = 60;
          this.count_down();
        })
        .catch((err) => {
          this.step = 2;
          console.log(err.response);
          this.check_loading = false;
        });
    },
    signup() {
      this.check_loading = true;
      let config = {
        method: "post",
        url: `create_customer`,
        headers: {
          Accept: "application/json",
        },
        data: {
          phone: this.customer.phone,
          name: this.customer.name,
          city: "khaza",
          store_name: this.$store.state.store_info.store_name,
        },
      };
      this.axios(config)
        .then((res) => {
          this.step = 3;
          this.check_loading = false;
          this.count = 60;
          this.count_down();
        })
        .catch((err) => {
          console.log(err.response);
          this.check_loading = false;
        });
    },
    close_login() {
      this.step = 1;
      this.count = 0;
      clearInterval(this.interval);
      this.$emit("close_dialog");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .close_login {
    position: relative;
    // span {
    //   position:absolute;
    //   display: block;
    //   width:100%;
    //   height:100%;
    //   background-color:rgba(255,255,255,0.4);
    //   left:0;
    //   top:0;
    // }
  }
}
</style>
